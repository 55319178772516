<template>
  <ProfileSettingsOverlayStyled>
    <HeaderStyled> API Token </HeaderStyled>
    <MainStyled>
      <div class="error">WARNING: NEVER SEND YOUR TOKEN TO ANYONE!</div>
      <input :disabled="true" :value="token" />
      <CopyButtonStyled class="copy copy-plain" @click="copyTokenToClipboard"> <CopyIcon /> copy to clipboard (plain) </CopyButtonStyled>
      <CopyButtonStyled class="copy copy-bearer" @click="copyTokenBearerToClipboard">
        <CopyIcon /> copy to clipboard (with Bearer)
      </CopyButtonStyled>
      <CopyButtonStyled class="copy copy-authorization" @click="copyAuthJsonToClipboard">
        <CopyIcon /> copy to clipboard (complete authorization header as json)
      </CopyButtonStyled>
    </MainStyled>
    <FooterStyled> </FooterStyled>
  </ProfileSettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CopyIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import { flexCenter } from '@styles/mixins'

const CopyButtonStyled = styled('button')`
  ${flexCenter}
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.primaryActive};
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ProfileSettingsOverlayStyled = styled('div')`
  display: grid;
  width: 100%;
  max-width: 400px;
  margin-top: 50px;
  grid-template-rows: 50px auto 50px;
  grid-template-columns: auto;
  grid-template-areas:
    'header'
    'main'
    'foooter';
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  grid-area: header;
  padding: 1rem;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

const MainStyled = styled('div')`
  grid-area: main;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.talpaGrey};

  input {
    padding: 0.5rem;
  }

  .copy {
    font-size: 0.75rem;
  }
`

const FooterStyled = styled('div')`
  grid-area: footer;
`

export default {
  components: {
    ProfileSettingsOverlayStyled,
    HeaderStyled,
    MainStyled,
    FooterStyled,
    CopyButtonStyled,
    CopyIcon,
  },
  data() {
    return {}
  },
  computed: {
    token() {
      return this.$keycloak.token
    },
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text)
      FlashMessages.$emit('success', `Copied to clipboard.`, { timeout: 1000 })
    },
    async copyTokenToClipboard() {
      await this.copyToClipboard(this.token)
    },
    async copyTokenBearerToClipboard() {
      await this.copyToClipboard(`Bearer ${this.token}`)
    },
    async copyAuthJsonToClipboard() {
      const s = JSON.stringify({
        authorization: `Bearer ${this.token}`,
      })
      await this.copyToClipboard(s)
    },
  },
  apollo: {},
}
</script>
