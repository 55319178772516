var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProfileSettingsOverlayStyled', [_c('HeaderStyled', [_vm._v(" API Token ")]), _c('MainStyled', [_c('div', {
    staticClass: "error"
  }, [_vm._v("WARNING: NEVER SEND YOUR TOKEN TO ANYONE!")]), _c('input', {
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.token
    }
  }), _c('CopyButtonStyled', {
    staticClass: "copy copy-plain",
    on: {
      "click": _vm.copyTokenToClipboard
    }
  }, [_c('CopyIcon'), _vm._v(" copy to clipboard (plain) ")], 1), _c('CopyButtonStyled', {
    staticClass: "copy copy-bearer",
    on: {
      "click": _vm.copyTokenBearerToClipboard
    }
  }, [_c('CopyIcon'), _vm._v(" copy to clipboard (with Bearer) ")], 1), _c('CopyButtonStyled', {
    staticClass: "copy copy-authorization",
    on: {
      "click": _vm.copyAuthJsonToClipboard
    }
  }, [_c('CopyIcon'), _vm._v(" copy to clipboard (complete authorization header as json) ")], 1)], 1), _c('FooterStyled')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }